<template>
    <div class="hourHeatMap">
        <div ref="heatmap" style="width: 100%; height: 100%"></div>
    </div>
</template>

<script>
import * as echarts from "echarts";

export default {
    data() {
        return {
            chart: null,
        };
    },
    mounted() {
        this.initHeatMap();
        window.addEventListener("resize", () => {
            this.resizeChart();
        });
        // setTimeout(() => {
        //   this.resizeChart();
        // }, 1);
    },
    beforeDestroy() {
        window.removeEventListener("resize", () => {
            this.resizeChart();
        });
        // if (this.chart) {
        //   this.chart.dispose();
        // }
    },
    methods: {
        initHeatMap() {
            this.chart = echarts.init(this.$refs.heatmap);

            function generateData() {
                const data = [];
                const days = ["월", "화", "수", "목", "금", "토", "일"];
                for (let day = 0; day < days.length; day++) {
                    for (let hour = 0; hour < 24; hour++) {
                        data.push([hour, day, Math.floor(Math.random() * 100)]);
                    }
                }
                return data;
            }

            const option = {
                tooltip: {
                    position: "top",
                    formatter: function(params) {
                        return `${params.value[1]}요일 ${params.value[0]}시: ${params.value[2]}`;
                    },
                },
                visualMap: {
                    min: 0,
                    max: 100,
                    calculable: true,
                    orient: "horizontal",
                    left: "center",
                    bottom: "2%",
                },
                xAxis: {
                    type: "category",
                    data: Array.from({ length: 24 }, (_, i) => `${i}시`),
                    splitArea: {
                        show: true,
                    },
                },
                yAxis: {
                    type: "category",
                    data: ["월", "화", "수", "목", "금", "토", "일"],
                    splitArea: {
                        show: true,
                    },
                },
                grid: {
                    width: "80%",
                    height: "65%",
                    top: "10%",
                },
                series: [
                    {
                        name: "Usage Pattern",
                        type: "heatmap",
                        data: generateData(),
                        label: {
                            show: true,
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    },
                ],
            };

            this.chart.setOption(option);
        },
        resizeChart() {
            if (this.chart) {
                this.chart.resize();
                // setTimeout(() => {
                //   this.resizeChart();
                // }, 1);
            }
        },
    },
};
</script>

<style scoped>
.hourHeatMap {
    width: 100%;
    height: 100%;
}
</style>
