<template>
  <div class="barContainer" ref="chart" />
</template>

<script>
import * as echarts from "echarts";

export default {
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.drawChart();
    window.addEventListener("resize", () => this.resizeChart);
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => this.resizeChart);
    // if (this.chart) {
    //   this.chart.dispose();
    // }
  },
  methods: {
    drawChart() {
      this.chart = echarts.init(this.$refs.chart);
      const option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          type: "category",
          data: [
            "A",
            "B",
            "C",
            "D",
            "E",
            "F",
            "G",
            "H",
            "I",
            "O",
            "A",
            "B",
            "C",
            "D",
            "E",
            "F",
            "G",
            "H",
            "I",
            "O",
            "A",
            "B",
            "C",
            "D",
            "E",
            "F",
            "G",
            "H",
            "I",
            "O",
          ],
        },
        grid: {
          width: "80%",
          height: "65%",
          top: "10%",
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            type: "bar",
            data: [
              120, -80, 70, -50, 10, 30, 60, 90, -50, -30, 120, -80, 70, -50,
              10, 30, 60, 90, -50, -30, 120, -80, 70, -50, 10, 30, 60, 90, -50,
              -30,
            ],
            itemStyle: {
              color: (params) => (params.value >= 0 ? "#73C9E6" : "#F56C6C"),
            },
          },
        ],
      };
      this.chart.setOption(option);
    },
    resizeChart() {
      if (this.chart) {
        this.chart.resize();
      }
    },
  },
};
</script>

<style scoped>
.barContainer {
  margin: 10px 0;
  width: 100%;
  height: 10%; /* 차트의 높이 조정 */
  /* background-color: rgb(195, 181, 221); */
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}
</style>
