<template>
    <div v-if="detailInfo" style="height: 100%">
        <div v-if="analysisMethod === 'Trend'" class="trendOutter">
            <div class="trendContainer">
                <trend-bar-chart-panel />
                <trend-line-chart-panel />
                <div class="compareResultTable">
                    <div class="row m-0" style="margin: 2rem 0px 0px 0px !important">
                        <div class="col-12 p-0 mb-2">
                            <div class="row m-0">
                                <div class="col-2 p-0">
                                    <span>{{ "비교 데이터" }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 pl-0">
                            <table class="table table-bordered text-center">
                                <thead>
                                    <tr class="table-secondary">
                                        <th scope="col">{{ "에너지 수급량" }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="table-info">
                                        <td>{{ "20.0kWh" }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-lg-8 pl-0">
                            <table class="table table-bordered text-center">
                                <thead>
                                    <tr class="table-secondary">
                                        <th scope="col">{{ "본원 7층 부하" }}</th>
                                        <th scope="col">{{ "본원 8층 부하" }}</th>
                                        <th scope="col">{{ "세종 부하" }}</th>
                                        <th scope="col">{{ "합계" }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{ "10.2kWh" }}</td>
                                        <td>{{ "7.3kWh" }}</td>
                                        <td>{{ "2.5kWh" }}</td>
                                        <td class="table-danger">{{ "20.0kWh" }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-lg-2 p-0">
                            <table class="table table-bordered text-center">
                                <thead>
                                    <tr class="table-secondary">
                                        <th scope="col">{{ "비교 결과" }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="table-warning">
                                        <td>{{ "+2.3 kWh (100%)" }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <trend-statistic-panel />
                <trend-heat-map-chart-panel-vue />
            </div>
        </div>
        <div v-else-if="analysisMethod === 'Diff'" style="height: 100%; width: 100%">
            <!-- chart -->
            <div class="row m-0" style="height: 500px">
                <CompareReverseLineChartPanel :chartResize="chartResize" />
            </div>
            <!-- table -->
            <div class="row m-0" style="margin: 2rem 0px 0px 0px !important">
                <div class="col-12 p-0 mb-2">
                    <div class="row m-0">
                        <div class="col-2 p-0">
                            <span>{{ "비교 데이터" }}</span>
                        </div>
                        <div class="col-10 p-0" style="display: flex; justify-content: end">
                            <span style="margin-right: 1rem">
                                {{ "선택 기간 : 전체 / 2024-11-03 08:00 ~ 2024-11-03 15:00" }}
                            </span>
                            <span style="margin-right: 1rem">{{ "표시 단위 : kWh" }}</span>
                            <div class="col-2 p-0">
                                <div class="row m-0">
                                    <div class="col-5 p-0" style="display: flex; justify-content: end">
                                        <label for="">{{ "차트 표시수 : " }}</label>
                                    </div>
                                    <div class="col-7 pr-0">
                                        <select v-model="chartDisp" class="form-control" name="" id="">
                                            <option :value="1">{{ "1" }}</option>
                                            <option :value="2">{{ "2" }}</option>
                                            <option :value="3">{{ "3" }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 pl-0">
                    <table class="table table-bordered text-center">
                        <thead>
                            <tr class="table-secondary">
                                <th scope="col">{{ "에너지 수급량" }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="table-info">
                                <td>{{ "20.0kWh" }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-lg-8 pl-0">
                    <table class="table table-bordered text-center">
                        <thead>
                            <tr class="table-secondary">
                                <th scope="col">{{ "본원 7층 부하" }}</th>
                                <th scope="col">{{ "본원 8층 부하" }}</th>
                                <th scope="col">{{ "세종 부하" }}</th>
                                <th scope="col">{{ "합계" }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ "10.2kWh" }}</td>
                                <td>{{ "7.3kWh" }}</td>
                                <td>{{ "2.5kWh" }}</td>
                                <td class="table-danger">{{ "20.0kWh" }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-lg-2 p-0">
                    <table class="table table-bordered text-center">
                        <thead>
                            <tr class="table-secondary">
                                <th scope="col">{{ "비교 결과" }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="table-warning">
                                <td>{{ "+2.3 kWh (100%)" }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- bottom line chart -->
            <div class="row m-0" style="height: 300px; margin: 2rem 0px 0px 0px !important">
                <div
                    class="p-0"
                    style="height: 100%"
                    :class="chartDisp == 1 ? 'col-12' : chartDisp == 2 ? 'col-6' : 'col-4'"
                >
                    <CompareLineChartPanel :chartDisp="chartDisp" :chartResize="chartResize" />
                </div>
                <div
                    class="p-0"
                    style="height: 100%"
                    :class="chartDisp == 1 ? 'col-12' : chartDisp == 2 ? 'col-6' : 'col-4'"
                >
                    <CompareLineChartPanel :chartDisp="chartDisp" :chartResize="chartResize" />
                </div>
                <div
                    class="p-0"
                    style="height: 100%"
                    :class="chartDisp == 1 ? 'col-12' : chartDisp == 2 ? 'col-6' : 'col-4'"
                >
                    <CompareLineChartPanel :chartDisp="chartDisp" :chartResize="chartResize" />
                </div>
            </div>
        </div>
        <div v-else-if="analysisMethod === 'Comp'" style="height: 100%; width: 100%">
            <!-- top line chart -->
            <div class="row m-0" style="height: 500px">
                <CompareReverseLineChartPanel :chartResize="chartResize" />
            </div>
            <!-- table -->
            <div class="row m-0" style="margin: 2rem 0px 0px 0px !important">
                <div class="col-12 p-0 mb-2">
                    <div class="row m-0">
                        <div class="col-2 p-0">
                            <span>{{ "비교 데이터" }}</span>
                        </div>
                        <div class="col-10 p-0" style="display: flex; justify-content: end">
                            <span style="margin-right: 1rem">
                                {{ "선택 기간 : 전체 / 2024-11-03 08:00 ~ 2024-11-03 15:00" }}
                            </span>
                            <span>{{ "표시 단위 : kWh" }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 pl-0">
                    <table class="table table-bordered text-center">
                        <thead>
                            <tr class="table-secondary">
                                <th scope="col">{{ "에너지 수급량" }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="table-warning">
                                <td>{{ "20.0kWh" }}</td>
                            </tr>
                            <tr class="table-warning">
                                <td>{{ "100%" }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-lg-10 p-0">
                    <table class="table table-bordered text-center">
                        <thead>
                            <tr class="table-secondary">
                                <th scope="col">{{ "본원 7층 전열" }}</th>
                                <th scope="col">{{ "본원 8층 난방" }}</th>
                                <th scope="col">{{ "세종 조명" }}</th>
                                <th scope="col">{{ "합계" }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ "10.2kWh" }}</td>
                                <td>{{ "7.3kWh" }}</td>
                                <td>{{ "2.5kWh" }}</td>
                                <td class="table-danger">{{ "20.0kWh" }}</td>
                            </tr>
                            <tr>
                                <td>{{ "54%" }}</td>
                                <td>{{ "23%" }}</td>
                                <td>{{ "23%" }}</td>
                                <td class="table-danger">{{ "100%" }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- bottom stack and doughnut chart -->
            <div class="row m-0" style="height: 300px; margin: 2rem 0px 0px 0px !important">
                <div class="col-9 pl-0" style="height: 100%">
                    <IngrStackChartPanel :chartResize="chartResize" />
                </div>
                <div class="col-3 pr-0" style="height: 100%">
                    <IngrDoughnutChartPanel :chartResize="chartResize" />
                </div>
            </div>
        </div>
        <div v-else-if="analysisMethod === 'Reltn'" style="height: 100%; width: 100%">
            <div class="row m-0" style="height: 100%">
                <!-- reverse line chart, scatter chart -->
                <div class="col-9 pl-0" style="height: 100%">
                    <div class="row m-0" style="height: 500px">
                        <CompareReverseLineChartPanel :chartResize="chartResize" />
                    </div>
                    <div class="row m-0" style="height: 500px">
                        <RltnScatterChartPanel :chartResize="chartResize" />
                    </div>
                </div>
                <!-- table -->
                <div class="col-3 pr-0">
                    <div class="equationContainer">
                        {{ "Y = 11.088 + 1.073 * X1" }}
                    </div>
                    <div class="table-container">
                        <div class="row m-0">
                            <b-table-simple bordered class="text-center">
                                <b-thead>
                                    <b-tr>
                                        <b-th>{{ "지표" }}</b-th>
                                        <b-th colspan="2">{{ "지표값" }}</b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <b-tr>
                                        <b-th :rowspan="2">{{ "회귀계수" }}</b-th>
                                        <b-th>{{ "Y절편" }}</b-th>
                                        <b-td>{{ "11.088" }}</b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>{{ "X1계수" }}</b-th>
                                        <b-td>{{ "1.073" }}</b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>{{ "R2" }}</b-th>
                                        <b-td colspan="2">{{ "0.691" }}</b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>{{ "보정 R2" }}</b-th>
                                        <b-td colspan="2">{{ "0.675" }}</b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th :rowspan="2">{{ "P-Value" }}</b-th>
                                        <b-th>{{ "Y절편" }}</b-th>
                                        <b-td>{{ "0.016" }}</b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>{{ "X1계수" }}</b-th>
                                        <b-td>{{ "0.8" }}</b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th :rowspan="1">{{ "다중공선성" }}</b-th>
                                        <b-th>{{ "X1" }}</b-th>
                                        <b-th>{{ "3.238" }}</b-th>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </div>
                    </div>
                    <div class="row m-0">
                        <div class="msgBox">
                            <div v-for="(item, index) in resultMsg" :key="index" v-html="highlightedText(item)"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="analysisMethod === 'Goal'" style="height: 100%; width: 100%">
            <div class="row m-0" style="height: 100%">
                <!-- waterfall chart -->
                <div class="col-12 p-0" style="height: 300px">
                    <!-- {{ "waterfall chart" }} -->
                    <GoalWaterFallChartPanel :chartResize="chartResize" />
                </div>
                <!-- table -->
                <div class="col-12 p-0">
                    <div class="row m-0">
                        <table class="table table-bordered text-center">
                            <thead>
                                <tr class="table-secondary">
                                    <th scope="col">{{ "[1] 월간 목표 설정" }}</th>
                                    <th scope="col">{{ "[2] 예상 소비량" }}</th>
                                    <th scope="col">{{ "[3] 예상 절감량" }}</th>
                                    <th scope="col">{{ "[4] 예상 절감비율(%)" }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{ "8,000kWh" }}</td>
                                    <td>{{ "7,000kWh" }}</td>
                                    <td>{{ "400kWh" }}</td>
                                    <td class="table-danger">{{ "5%" }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- 연간 테이블 (월간일 경우 안보여야함) -->
                    <div class="row m-0">
                        <table class="table table-bordered text-center">
                            <thead>
                                <tr class="table-secondary">
                                    <th scope="col">{{ "구분" }}</th>
                                    <th v-for="mon in yearTableData.month" :key="mon" scope="col">
                                        {{ mon }}
                                    </th>
                                    <th scope="col">{{ "합계" }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="table-secondary">{{ "목표 설정(kWh)" }}</td>
                                    <td
                                        v-for="(target, index) in yearTableData.targetSetValue"
                                        :class="index < currentMonth ? 'table-warning' : ''"
                                        :key="index + 10"
                                    >
                                        {{ target ?? "-" }}<br />
                                        <!-- {{ `${currentMonth}, ${index}` }} -->
                                    </td>
                                    <td class="table-danger">{{ sumTargetValue }}</td>
                                </tr>
                                <tr>
                                    <td class="table-secondary">{{ "사용량(kWh)" }}</td>
                                    <td
                                        v-for="(use, index) in yearTableData.useValue"
                                        :class="index < currentMonth ? 'table-warning' : ''"
                                        :key="index + 100"
                                    >
                                        {{ use ?? "-" }}
                                    </td>
                                    <td class="table-danger">{{ sumUseValue }}</td>
                                </tr>
                                <tr>
                                    <td class="table-secondary">{{ "절감량(kWh)" }}</td>
                                    <td
                                        v-for="(disVal, index) in yearTableData.disValue"
                                        :class="index < currentMonth ? 'table-warning' : ''"
                                        :key="index + 1000"
                                    >
                                        {{ disVal ?? "-" }}
                                    </td>
                                    <td class="table-danger">{{ sumDisValue }}</td>
                                </tr>
                                <tr>
                                    <td class="table-secondary">{{ "절감률(%)" }}</td>
                                    <td
                                        v-for="(disP, index) in yearTableData.disPer"
                                        :class="index < currentMonth ? 'table-warning' : ''"
                                        :key="index + 10000"
                                    >
                                        {{ disP ?? "-" }}
                                    </td>
                                    <td class="table-danger">{{ sumDisPercentValue }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- description -->
                <div class="col-12 p-0">
                    <p class="mb-1">
                        {{ "[1] 월간 목표 설정 : 사전 입력된 월간 목표 설정량" }}
                    </p>
                    <p class="mb-1">
                        {{ "[2] 예상 소비량 : 월간 소비량 데이터를 회귀분석과 이동평균 보정을 사용하여 추정치" }}
                    </p>
                    <p class="mb-1">
                        {{ "[3] 예상 절감량 : [1] 설정 목표량 – [2] 예상 소비량" }}
                    </p>
                    <p class="mb-3">
                        {{ "[4] 예상 절감비율 : [3] 예상 절감량 / [1] 설정 목표량 * 100" }}
                    </p>
                    <p class="mb-0">
                        {{
                            "※ 예상 절감량 분석은 추이 분석을 위해 매월 7일 이후 조회 가능하고 월 후반기에 갈 수록 정확도가 높아집니다."
                        }}
                    </p>
                </div>
            </div>
        </div>
        <div v-else-if="analysisMethod === 'Cost'" style="height: 100%; width: 100%">
            {{ "비용 분석 결과" }}
        </div>
        <h4 v-else>{{ "no match analysis way" }}</h4>
    </div>
</template>

<script>
import TrendBarChartPanel from "../charts/TrendBarChartPanel.vue";
import TrendHeatMapChartPanelVue from "../charts/TrendHeatMapChartPanel.vue";
import TrendLineChartPanel from "../charts/TrendLineChartPanel.vue";
import TrendStatisticPanel from "../charts/TrendStatisticPanel.vue";
// 비교 분석 차트 패널(CompareReverseLineChartPanel 영역을 잡기 위한 임시 컴포넌트)
import CompareReverseLineChartPanel from "../charts/CompareReverseLineChartPanel.vue";
import CompareLineChartPanel from "../charts/CompareLineChartPanel.vue";
// 성분 분석
import IngrStackChartPanel from "../charts/IngrStackChartPanel.vue";
import IngrDoughnutChartPanel from "../charts/IngrDoughnutChartPanel.vue";
// 상관분석
import RltnScatterChartPanel from "../charts/RltnScatterChartPanel.vue";
// 목표분석
import GoalWaterFallChartPanel from "../charts/GoalWaterFallChartPanel.vue";
import GoalTableDummyData from "../charts/GoalTableDummyData.json";

export default {
    props: ["detailInfo", "chartResize", "analysisMethod"],
    components: {
        TrendBarChartPanel,
        TrendHeatMapChartPanelVue,
        TrendLineChartPanel,
        TrendStatisticPanel,

        CompareReverseLineChartPanel,
        CompareLineChartPanel,

        IngrStackChartPanel,
        IngrDoughnutChartPanel,

        RltnScatterChartPanel,

        GoalWaterFallChartPanel,
    },
    data() {
        return {
            chartDisp: 3,
            currentMonth: new Date().getMonth(),
            highlightingKeyword: ["Adjusted R", "회귀 계수", "p-value", "VIF"],
            // 임시
            resultMsg: [
                "Adjusted R² (0.911) 값이 높아, 모델이 데이터를 잘 설명하고 있습니다.",
                "회귀 계수 1 (9.759)에 대한 p-value가 유의미 (0.001)하여, 이 변수가 모델에 의미 있게 기여하고 있습니다.",
                "회귀 계수 2 (4.503)에 대한 p-value가 유의미하지 않음 (0.069)으로, 이 변수가 모델에 크게 기여하지 않고 있을 가능성이 있습니다.",
                "회귀 계수 1의 VIF 값이 11.97로, 높은 다중공선성 문제가 존재합니다.",
            ],
            yearTableData: null,
        };
    },
    computed: {
        sumTargetValue() {
            // <목표분석> 목표 설정 합산
            return GoalTableDummyData.targetSetValue.slice(0, this.currentMonth).reduce((acc, cur) => acc + cur, 0);
        },
        sumUseValue() {
            // <목표분석> 사용량 합산
            return GoalTableDummyData.useValue.slice(0, this.currentMonth).reduce((acc, cur) => acc + cur, 0);
        },
        sumDisValue() {
            // <목표분석> 절감량 합산
            return GoalTableDummyData.disValue.slice(0, this.currentMonth).reduce((acc, cur) => acc + cur, 0);
        },
        sumDisPercentValue() {
            // <목표분석> 합산값에 대한 절감률
            return ((this.sumDisValue / this.sumTargetValue) * 100).toFixed(0);
        },
    },
    watch: {
        // analysisWay(newVal) {
        //     console.log("analysisWay is changed in ResultChartPanel:", newVal);
        // },
    },
    created() {
        this.yearTableData = GoalTableDummyData;
    },
    mounted() {},
    methods: {
        highlightedText(text) {
            const keywordPattern = this.highlightingKeyword.join("|");
            const regex = new RegExp(`(${keywordPattern})`, "gi");
            return text.replace(regex, '<span class="highlightMsg">$1</span>');
        },
    },
};
</script>

<style scoped>
.trendOutter {
    height: 100%;
    /* background-color: red; */
}
.trendContainer {
    width: 100%;
    height: 100%;
    /* background-color: yellow; */
}
.compareResultTable {
    width: 100%;
    height: 10%;
    /* background-color: darkgreen; */
}

.equationContainer {
    display: flex;
    justify-content: center;
    background-color: #555;
    color: yellow;
    border-radius: 5px;
    line-height: 35px;
    font-weight: 600;
}

.table-container {
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    margin-top: 5px;
}

.msgBox {
    margin-top: 4px;
    padding: 4px;
    border: solid #bbb 1px;
    border-radius: 5px;
}

.equationContainer {
    display: flex;
    justify-content: center;
    background-color: #555;
    color: yellow;
    border-radius: 5px;
    line-height: 35px;
    font-weight: 600;
}

.table-container {
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    margin-top: 5px;
}

.msgBox {
    margin-top: 4px;
    padding: 4px;
    border: solid #bbb 1px;
    border-radius: 5px;
}
</style>
