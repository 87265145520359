<template>
  <div class="statisticContainer">
    <div class="analysisTable">
      <div class="firstTable">
        <b-table-simple bordered class="text-center">
          <b-thead>
            <b-tr>
              <b-th>지표</b-th>
              <b-th>지표값</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-th>최소값</b-th>
              <b-td>23</b-td>
            </b-tr>
            <b-tr>
              <b-th>최대값</b-th>
              <b-td>32</b-td>
            </b-tr>
            <b-tr>
              <b-th>평균값</b-th>
              <b-td>15</b-td>
            </b-tr>
            <b-tr>
              <b-th>표준편차</b-th>
              <b-td>20</b-td>
            </b-tr>
            <b-tr>
              <b-th>1사분위수(Q1)</b-th>
              <b-td>35</b-td>
            </b-tr>
            <b-tr>
              <b-th>2사분위수(Q2)</b-th>
              <b-td>56</b-td>
            </b-tr>
            <b-tr>
              <b-th>3사분위수(Q3)</b-th>
              <b-td>56</b-td>
            </b-tr>
            <b-tr>
              <b-th>분산</b-th>
              <b-td>12</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
    </div>
    <div class="dataScatterBar" ref="barChart">데이터분포도</div>
    <div class="dataOutlier">
      <b-table-simple bordered class="text-center align-middle">
        <b-thead>
          <b-tr>
            <b-th>이상 위치</b-th>
            <b-th>이상값</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-th>2024-09-10</b-th>
            <b-td>32</b-td>
          </b-tr>
          <b-tr>
            <b-th>2024-09-10</b-th>
            <b-td>32</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.initBarChart();
    window.addEventListener("resize", () => this.resizeChart);
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => this.resizeChart);
    // if (this.chart) {
    //   this.chart.dispose();
    // }
  },
  methods: {
    initBarChart() {
      this.chart = echarts.init(this.$refs.barChart);
      const option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          type: "category",
          data: ["A", "B", "C", "D", "E"],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            type: "bar",
            data: [23, 45, 56, 78, 90],
            itemStyle: {
              color: "#73C9E6",
            },
          },
        ],
      };
      this.chart.setOption(option);
    },
    resizeChart() {
      if (this.chart) {
        this.chart.resize();
      }
    },
  },
};
</script>

<style scoped>
.statisticContainer {
  margin: 10px 0;
  width: 100%;
  height: 35%;
  display: flex;
  justify-content: center;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  /* background-color: blueviolet; */
}
.firstTable {
  height: 100%;
}
.analysisTable {
  width: 30%;
  /* background-color: darkmagenta; */
}
.dataScatterBar {
  width: 30%;
  height: 100%;
  /* background-color: darkolivegreen; */
}
.dataOutlier {
  width: 30%;
  /* background-color: darkorange; */
}
</style>
