<template>
    <div class="row m-0">
        <div class="col-lg-3">
            <div class="row align-items-center" style="margin: 0px 0px 10px 0px !important">
                <div class="col-3 p-0 d-flex justify-content-end">
                    <label class="m-0" for="ptIdx">{{ "분석 데이터 : " }}</label>
                </div>
                <div class="col-9 pr-0">
                    <div class="input-group">
                        <input
                            class="form-control"
                            type="text"
                            :value="`${targetPtName ?? '-'} (${detailInfo.ptIdx ?? '-'})`"
                            disabled
                        />
                        <div class="input-group-append">
                            <button
                                class="btn btn-secondary"
                                type="button"
                                v-b-toggle.pointSideBar
                                @click="showSideBar('target')"
                                :disabled="!isEditMode"
                            >
                                {{ "선택" }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row align-items-center" style="margin: 0px 0px 10px 0px !important">
                <div class="col-3 p-0 d-flex justify-content-end">
                    <label class="m-0" for="analysisMethod">{{ "분석 방법 : " }}</label>
                </div>
                <div class="col-9 pr-0">
                    <select
                        class="form-control"
                        id="analysisMethod"
                        v-model="detailInfo.analysisMethod"
                        :disabled="!isEditMode"
                    >
                        <option :value="null">{{ "분석 방법 선택" }}</option>
                        <option
                            v-for="method in $store.state.commonCodes.analysisMethod"
                            :key="method.value"
                            :value="method.value"
                        >
                            {{ method.text }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row align-items-center" style="margin: 0px 0px 10px 0px !important">
                <div class="col-3 p-0 d-flex justify-content-end">
                    <label class="m-0" for="dispType">{{ "표시 방법 : " }}</label>
                </div>
                <div class="col-9 pr-0">
                    <select v-model="detailInfo.dispType" class="form-control" name="dispType" :disabled="!isEditMode">
                        <option :value="null">{{ "표시 방법 선택" }}</option>
                        <option v-for="type in $store.state.commonCodes.dispType" :key="type.value" :value="type.value">
                            {{ type.text }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row align-items-center" style="margin: 0px !important">
                <div class="col-3 p-0 d-flex justify-content-end">
                    <label class="m-0" for="dispUnit">{{ "단위 환산 : " }}</label>
                </div>
                <div class="col-9 pr-0">
                    <select v-model="detailInfo.dispUnit" class="form-control" name="dispUnit" :disabled="!isEditMode">
                        <option :value="null">{{ "단위 환산 선택" }}</option>
                        <option v-for="type in $store.state.commonCodes.dispUnit" :key="type.value" :value="type.value">
                            {{ type.text }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-lg-2">
            <div class="row m-0">
                <div class="col-12 p-0 mb-2 d-flex justify-content-between align-items-center">
                    <div>
                        <label class="m-0">{{ "성분 구성" }}</label>
                    </div>
                    <div class="btn-group">
                        <button
                            class="btn btn-sm btn-primary"
                            type="button"
                            v-b-toggle.pointSideBar
                            @click="showSideBar('member')"
                            :disabled="!isEditMode"
                        >
                            {{ "+" }}
                        </button>
                        <button class="btn btn-sm btn-danger" type="button" :disabled="!isEditMode">
                            {{ "-" }}
                        </button>
                    </div>
                </div>
                <div class="col-12 p-0">
                    <div class="point-list-ctn">
                        <div
                            v-for="member in mamberPtList"
                            :key="member.ptIdx"
                            class="form-check"
                            style="margin-bottom: 0.2rem;"
                        >
                            <!-- v-model="type.checked" -->
                            <!-- @change="onEquipTypeChange(type.equipType, type.checked, type.equipTypeName)" -->
                            <input
                                class="form-check-input"
                                type="checkbox"
                                :id="member.ptIdx"
                                :disabled="!isEditMode"
                            />
                            <label class="form-check-label" :for="member.ptIdx">
                                {{ pointList.find((point) => point.ptIdx == member.ptIdx).ptName ?? "-" }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2">
            <div class="row m-0">
                <div class="col-12 p-0 mb-2 d-flex justify-content-between align-items-center">
                    <div>
                        <label class="m-0">{{ "영향 인자" }}</label>
                    </div>
                    <div class="btn-group">
                        <button
                            class="btn btn-sm btn-primary"
                            type="button"
                            v-b-toggle.pointSideBar
                            @click="showSideBar('factor')"
                            :disabled="!isEditMode"
                        >
                            {{ "+" }}
                        </button>
                        <button class="btn btn-sm btn-danger" type="button" :disabled="!isEditMode">
                            {{ "-" }}
                        </button>
                    </div>
                </div>
                <div class="col-12 p-0">
                    <div class="point-list-ctn">
                        <div
                            v-for="factor in factorPtList"
                            :key="factor.ptIdx"
                            class="form-check"
                            style="margin-bottom: 0.2rem;"
                        >
                            <!-- v-model="type.checked" -->
                            <!-- @change="onEquipTypeChange(type.equipType, type.checked, type.equipTypeName)" -->
                            <input
                                class="form-check-input"
                                type="checkbox"
                                :id="factor.ptIdx"
                                :disabled="!isEditMode"
                            />
                            <label class="form-check-label" :for="factor.ptIdx">
                                {{ pointList.find((point) => point.ptIdx == factor.ptIdx).ptName ?? "-" }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="row align-items-center" style="margin: 0px 0px 10px 0px !important">
                <div class="col-3 p-0 d-flex justify-content-end">
                    <label class="m-0" for="timeDsvn">{{ "데이터 유형 : " }}</label>
                </div>
                <div class="col-9 pr-0">
                    <select class="form-control" id="timeDsvn" v-model="detailInfo.timeDsvn" :disabled="!isEditMode">
                        <option :value="null">{{ "데이터 유형 선택" }}</option>
                        <option
                            v-for="dateType in $store.state.commonCodes.timeDsvn"
                            :key="dateType.value"
                            :value="dateType.value"
                        >
                            {{ dateType.text }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row align-items-center" style="margin: 0px 0px 10px 0px !important">
                <div class="col-3 p-0 d-flex justify-content-end">
                    <label class="m-0" for="analysisDate">{{ "분석 기간 : " }}</label>
                </div>
                <calendar-component
                    start="true"
                    class="col-9 pr-0"
                    v-model="baseDayTime"
                    :calendarType="detailInfo.timeDsvn"
                    @input="getBaseDayTime"
                    placeholder="기본 기간 선택"
                    :disabledCalendar="!isEditMode"
                />
            </div>
            <div class="row align-items-center" style="margin: 0px 0px 10px 0px !important">
                <div class="col-3 p-0 d-flex justify-content-end">
                    <label class="m-0" for="compareType">{{ "비교 방법 : " }}</label>
                </div>
                <div class="col-9 pr-0">
                    <select
                        class="form-control"
                        id="compareType"
                        :class="{ 'disabled-select': !detailInfo.timeDsvn }"
                        v-model="detailInfo.compareType"
                        :disabled="!isEditMode || !detailInfo.timeDsvn"
                    >
                        <option :value="null">{{ "비교 없음" }}</option>
                        <option v-for="comp in compareTypeArr" :key="comp.value" :value="comp.value">
                            {{ comp.text }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row align-items-center" style="margin: 0px !important">
                <div class="col-3 p-0 d-flex justify-content-end">
                    <label class="m-0" for="comparePeriod">{{ "비교 기간 : " }}</label>
                </div>
                <!-- <date-picker class="col-9 pr-0" range valueType="YYYY-MM-DD" lang="ko"
                    placeholder="비교 기간 선택"></date-picker> -->
                <calendar-component
                    end="true"
                    class="col-9 pr-0"
                    v-model="compareDayTime"
                    :calendarType="detailInfo.timeDsvn"
                    :baseDayTime="baseDayTime"
                    :compareType="detailInfo.compareType"
                    @input="getCompareDayTime"
                    placeholder="비교 기간 선택"
                    :disabledCalendar="!isEditMode"
                />
            </div>
        </div>
        <div class="col-lg-2">
            <div class="col-12 p-0" style="margin-bottom: 10px">
                <div class="row m-0">
                    <div class="col-3 p-0">
                        <div style="width: 60px; height: 60px;">
                            <span class="icon-ctn" @click="toggleIconSelect" :disabled="!isEditMode">
                                <i :class="!isEmpty(detailInfo.icon) ? detailInfo.icon : 'fa fa-times'"></i>
                            </span>
                            <icon-select
                                v-if="isIconSelect"
                                :current="''"
                                :hideText="true"
                                :positionLeft="'-5rem'"
                                @on-change="onChangeIcon"
                            />
                        </div>
                    </div>
                    <div class="col-9 p-0">
                        <div v-if="!isNewMode" class="col-12 p-0" style="display: flex; justify-content: end">
                            <b-form-checkbox v-model="isEditMode" switch size="lg" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row m-0">
                <div class="mb-2" style="width: 100%; display: flex; flex: 1">
                    <button
                        class="btn btn-sm btn-outline-secondary"
                        type="button"
                        @click="() => $refs['setting-mgmt'].show()"
                        style="flex: 1"
                        :disabled="!isEditMode"
                    >
                        {{ "설정" }}
                    </button>
                    <button
                        class="btn btn-sm btn-outline-secondary"
                        type="button"
                        @click="() => $refs['set-menu'].show()"
                        style="flex: 1; margin: 0px 0.4rem 0px 0.4rem;"
                        :disabled="!isEditMode"
                    >
                        {{ "노출" }}
                    </button>
                    <!-- @click="() => $refs['analysis-history'].show()" -->
                    <button
                        class="btn btn-sm btn-outline-secondary"
                        type="button"
                        style="flex: 1"
                        @click="() => $refs['analysis-history'].show()"
                        :disabled="!isEditMode"
                    >
                        {{ "기록" }}
                    </button>
                </div>
                <div class="col-12 p-0 mb-2">
                    <button
                        type="button"
                        class="btn btn-sm btn-outline-secondary"
                        style="width: 100%"
                        @click="getAnalResultData"
                        :disabled="!isEditMode"
                    >
                        <i class="fas fa-share-square mr-2" aria-hidden="true"></i>
                        <span>{{ "분석 실행" }}</span>
                    </button>
                </div>

                <div style="width: 100%; display: flex; flex: 1;">
                    <button
                        type="button"
                        class="btn btn-sm btn-outline-secondary"
                        style="flex: 1; margin-right: 0.2rem;"
                        @click="exportPDF"
                        :disabled="!isEditMode"
                    >
                        <i class="fas fa-download mr-2" aria-hidden="true"></i>
                        <span>{{ "보고서 저장" }}</span>
                    </button>
                    <button
                        type="button"
                        class="btn btn-sm btn-outline-secondary"
                        style="flex: 1; margin-left: 0.2rem;"
                        :disabled="!isEditMode"
                    >
                        <span>{{ "데이터 다운로드" }}</span>
                    </button>
                </div>
            </div>
        </div>
        <!-- 관제점 조회 사이드바 -->
        <b-sidebar
            ref="PointSideBar"
            id="pointSideBar"
            bg-variant="white"
            backdrop-variant="secondary"
            width="45vw"
            @hidden="closeSidebar"
            no-header
            shadow
            right
            backdrop
        >
            <div v-if="sideBar" style="padding: 1rem; height: 100%">
                <SearchComponent @close="closeSidebar" @select-point="setPointInfoData" />
            </div>
        </b-sidebar>
        <!-- 설정 관리 모달 -->
        <b-modal ref="setting-mgmt" centered @hidden="closeSettingMgmtModal">
            <template #modal-header>
                <h5 class="m-0">{{ "설정 관리" }}</h5>
            </template>
            <SettingMgmtModalPanel ref="SettingMgmtModalPanel" :detailInfo="detailInfo" :analysisList="analysisList" />
            <template #modal-footer>
                <div style="display: flex; flex: 1">
                    <button
                        class="btn btn-primary"
                        type="button"
                        style="flex: 1; margin-right: 0.5rem"
                        @click="getSettingMgmt"
                    >
                        {{ "저장" }}
                    </button>
                    <button
                        class="btn btn-secondary"
                        type="button"
                        style="flex: 1; margin-left: 0.5rem"
                        @click="closeSettingMgmtModal"
                    >
                        {{ "취소" }}
                    </button>
                </div>
            </template>
        </b-modal>
        <!-- 노출 관리 모달 -->
        <b-modal ref="set-menu" centered @hidden="() => $refs['set-menu'].hide()">
            <template #modal-header>
                <h5 class="m-0">{{ "노출 관리" }}</h5>
            </template>
            <SetMenuModalPanel :detailInfo="detailInfo" :mode="mode" />
            <template #modal-footer>
                <div style="display: flex; flex: 1">
                    <button
                        class="btn btn-primary"
                        type="button"
                        style="flex: 1; margin-right: 0.5rem"
                        @click="() => $refs['set-menu'].hide()"
                    >
                        {{ "적용" }}
                    </button>
                    <button
                        class="btn btn-secondary"
                        type="button"
                        style="flex: 1; margin-left: 0.5rem"
                        @click="() => $refs['set-menu'].hide()"
                    >
                        {{ "닫기" }}
                    </button>
                </div>
            </template>
        </b-modal>
        <!-- 분석 기록 관리 모달 -->
        <b-modal ref="analysis-history" size="lg" centered @hidden="() => $refs['analysis-history'].hide()">
            <template #modal-header>
                <h5 class="m-0">{{ "분석 기록 관리" }}</h5>
            </template>
            <HistoryMgmtModalPanel :detailInfo="detailInfo" />
            <template #modal-footer>
                <div style="display: flex; flex: 1">
                    <button
                        class="btn btn-secondary"
                        type="button"
                        style="flex: 1"
                        @click="() => $refs['analysis-history'].hide()"
                    >
                        {{ "닫기" }}
                    </button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import SearchComponent from "@src/views/component/v2.1/PointSearch/Search.vue";
// import DatePicker from 'vue2-datepicker';
// import 'vue2-datepicker/index.css';
import SettingMgmtModalPanel from "./SettingMgmtModalPanel.vue";
import SetMenuModalPanel from "./SetMenuModalPanel.vue";
import HistoryMgmtModalPanel from "./HistoryMgmtModalPanel.vue";
import CalendarComponent from "@src/views/playground/CalendarComponent.vue";

import { mapState } from "vuex";

export default {
    props: ["detailInfo", "analysisList"],
    components: {
        SearchComponent,
        // DatePicker,
        SettingMgmtModalPanel,
        SetMenuModalPanel,
        HistoryMgmtModalPanel,
        CalendarComponent,
    },
    data() {
        return {
            sideBarBtnType: null,

            baseDayTime: [],
            compareDayTime: [],
            // calendarType: null,
            sideBar: false,
            // compareType: null,
            compareTypeArr: [],
            mode: "new",
            isIconSelect: false,
        };
    },
    computed: {
        ...mapState({
            pointList: (state) => state.pointList,
        }),
        targetPtName() {
            let find = this.pointList.find((point) => point.ptIdx == this.detailInfo.ptIdx);
            return !this.isEmpty(find) ? find.ptName : null;
        },
        mamberPtList() {
            return this.detailInfo.ptMaps.filter((item) => item.ptReltnType !== "Factor");
        },
        factorPtList() {
            return this.detailInfo.ptMaps.filter((item) => item.ptReltnType == "Factor");
        },
        isEditMode: {
            get() {
                return this.mode == "edit" || this.mode == "new";
            },
            set(value) {
                if (value) {
                    if (this.mode !== "new") this.mode = "edit";
                } else this.mode = "view";
                // this.mode = value ? "edit" : "view";
            },
        },
        isNewMode() {
            return this.mode == "new";
        },
    },
    watch: {
        "detailInfo.timeDsvn": {
            deep: true,
            handler(type) {
                this.customCompareType(type);
                if (this.isEditMode) this.detailInfo.compareType = null;
                if (type === null) {
                    this.baseDayTime = [];
                    this.compareDayTime = [];
                }
                if (type === "Year") this.detailInfo.compareType = null;
            },
        },
        "detailInfo.compareType": {
            deep: true,
            handler(compareType) {
                if (compareType === null) {
                    // this.compareDayTime = [];
                    this.detailInfo.fromDateComp = null;
                    this.detailInfo.toDateComp = null;
                }
            },
        },
    },
    created() {},
    mounted() {
        this.customCompareType();
    },
    methods: {
        customCompareType(type) {
            const compareTypeArr = this.$store.state.commonCodes.compareType;
            const filterConditions = {
                YEAR: (value) => value === "User",
                MONTH: (value) => ["LastYear", "LastMonth", "User"].includes(value),
                DAY: (value) => ["LastYear", "LastMonth", "LastWeek", "LastDay", "User"].includes(value),
                HOUR: (value) => ["LastYear", "LastMonth", "LastWeek", "LastDay", "User"].includes(value),
                QUARTER: (value) => ["LastYear", "LastMonth", "LastWeek", "LastDay", "User"].includes(value),
            };

            const customCompareTypeArr = compareTypeArr.filter((item) => {
                const condition = filterConditions[type];
                return condition ? condition(item.value) : true;
            });
            this.compareTypeArr = customCompareTypeArr;
        },
        showSideBar(btnType) {
            this.sideBarBtnType = btnType;
            this.sideBar = true;
        },
        closeSidebar() {
            this.$refs.PointSideBar.hide();
            this.sideBar = false;
        },
        exportPDF() {
            this.$emit("exportToPDF");
        },
        getAnalResultData() {
            // this.$emit("changeAnalysisWay", this.detailInfo.analysisMethod);
            // this.$emit("analysisRun");
            this.$emit("result");
        },
        async setPointInfoData(info) {
            if (this.sideBarBtnType == "target") {
                this.detailInfo.ptIdx = info.ptIdx;
            } else if (this.sideBarBtnType == "member") {
                this.detailInfo.ptMaps.push({ ptIdx: info.ptIdx, ptReltnType: "Member" });
            } else if (this.sideBarBtnType == "factor") {
                this.detailInfo.ptMaps.push({ ptIdx: info.ptIdx, ptReltnType: "Factor" });
            } else return;
            await this.closeSidebar();
        },
        setDayTime(detail) {
            if (detail.fromDate && detail.toDate) {
                this.baseDayTime = [];
                this.baseDayTime.push(detail.fromDate, detail.toDate);
            }
            if (detail.fromDateComp && detail.toDateComp) {
                this.compareDayTime = [];
                this.compareDayTime.push(detail.fromDateComp, detail.toDateComp);
            }
        },
        getBaseDayTime(baseDay) {
            if (!this.isEmpty(baseDay) && baseDay.length === 2) {
                this.detailInfo.fromDate = baseDay[0];
                this.detailInfo.toDate = baseDay[1];
            } else if (typeof baseDay === "string") {
                this.detailInfo.fromDate = baseDay;
                this.detailInfo.toDate = baseDay;
            } else return;
        },
        getCompareDayTime(compDay) {
            if (!this.isEmpty(compDay) && compDay.length === 2) {
                this.detailInfo.fromDateComp = compDay[0];
                this.detailInfo.toDateComp = compDay[1];
            } else if (typeof compDay === "string") {
                this.detailInfo.fromDateComp = compDay;
                this.detailInfo.toDateComp = compDay;
            } else return;
        },
        setEditMode() {
            console.log("call edit mode");
            this.mode = "edit";
        },
        setNewMode() {
            console.log("call new mode");
            this.mode = "new";
        },
        setViewMode() {
            console.log("call view mode");
            this.mode = "view";
        },
        toggleIconSelect() {
            this.isIconSelect = !this.isIconSelect;
        },
        onChangeIcon(value) {
            if (value) {
                this.detailInfo.icon = value.icon;
                this.toggleIconSelect();
                console.log(value.icon);
            } else return;
        },
        async getSettingMgmt() {
            const setting = this.$refs["SettingMgmtModalPanel"].settingInfo;
            if (!this.isEmpty(setting)) {
                this.detailInfo.modelName = setting.modelName;
                this.detailInfo.category = setting.category;
                this.detailInfo.enabled = setting.enabled;
                this.detailInfo.description = setting.description;
            }
            await this.$refs["setting-mgmt"].hide();
        },
        closeSettingMgmtModal() {
            this.$refs["setting-mgmt"].hide();
            // if (this.isNewMode) {
            //     console.log("model setting info init");
            //     this.detailInfo.modelName = null;
            //     this.detailInfo.category = null;
            //     this.detailInfo.description = null;
            // }
        },
    },
};
</script>

<style scoped>
.point-list-ctn {
    width: 100%;
    height: 130px;
    overflow-y: auto;
    border: 1px solid #ececec;
    border-radius: 5px;
    padding: 0.5rem;
    background-color: white;
}
.disabled-select {
    cursor: not-allowed;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    background-color: #f3f3f3;
    border-color: #ccc;
}
.icon-ctn {
    width: 100%;
    height: 100%;
    background-color: #eceff1;
    cursor: pointer;
    font-size: 2rem;
    color: #455a64;
    border-radius: 5px;
    border-left: solid 1px #cfd8dc;
    border-top: solid 1px #cfd8dc;
    border-right: solid 1px #90a4ae;
    border-bottom: solid 1px #90a4ae;
    transition: background-color 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
}
.icon-ctn:hover {
    color: #eceff1;
    background-color: #455a64;
}
</style>
