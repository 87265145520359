<template>
    <div class="row m-0">
        <div class="col-12 p-0 mb-2" style="display: flex; justify-content: center; align-items: center;">
            <div style="width: 60px; height: 60px;">
                <span class="icon-ctn">
                    <i :class="!isEmpty(detailInfo.icon) ? detailInfo.icon : 'fa fa-times'" aria-hidden="true"></i>
                </span>
            </div>
            <div style="width: calc(100% - 90px); margin-left: 1rem;">
                <label for="menuName">{{ "표시 메뉴명 : " }}</label>
                <input
                    v-model="detailInfo.publish.title"
                    class="form-control"
                    type="text"
                    id="menuName"
                    placeholder="표시 메뉴명을 입력하세요."
                />
            </div>
        </div>
        <!-- {{ detailInfo.publish }} -->
        <div class="col-12 mb-2">
            <div class="row m-0">
                <div class="col-3 p-0 d-flex justify-content-end">
                    <label class="m-0" for="position">{{ "노출 위치 선택 : " }}</label>
                </div>
                <div class="col-9 pr-0">
                    <div class="input-group">
                        <input
                            v-model="detailInfo.publish.path"
                            class="form-control"
                            type="text"
                            id="position"
                            placeholder="메뉴 URL 을 입력하세요."
                        />
                        <div class="input-group-append">
                            <button v-b-toggle.menuSideBar class="btn btn-secondary" type="button">{{ "선택" }}</button>
                        </div>
                    </div>
                    <div v-if="detailInfo.publish.parentMenuIdx" style="color: #BDBDBD;">
                        <span>
                            {{ `${selectMenuName} 메뉴 하위에 메뉴를 노출 시킵니다.` }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="row m-0">
                <div class="col-3 p-0 d-flex justify-content-end">
                    <label class="m-0" for="position">{{ "접근 허용 : " }}</label>
                </div>
                <div class="col-9 pr-0">
                    <div class="access-ctn">
                        <div
                            v-for="(group, index) in groupList"
                            :key="group.groupIdx"
                            class="form-check"
                            style="margin-bottom: 0.2rem;"
                        >
                            <!-- v-model="type.checked" -->
                            <!-- @change="onEquipTypeChange(type.equipType, type.checked, type.equipTypeName)" -->
                            <input
                                v-model="groupCheck[index]"
                                class="form-check-input"
                                type="checkbox"
                                :id="group.builtIn"
                                @change="chageGroupAccess"
                            />
                            <label class="form-check-label" :for="group.builtIn">{{ group.groupName }}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-sidebar
            ref="menuSideBar"
            id="menuSideBar"
            bg-variant="white"
            backdrop-variant="secondary"
            width="23vw"
            @hidden="closeSidebar"
            no-header
            shadow
            right
            backdrop
        >
            <div style="padding: 1rem;">
                <h5 class="mb-3">{{ "메뉴 목록" }}</h5>
                <div class="tree-area" v-if="Array.isArray(treeData) && treeData.length > 0 && isShow">
                    <liquor-tree
                        class="tree-item"
                        ref="tree"
                        :data="treeData"
                        :options="treeOptions"
                        @node:selected="onNodeSelected"
                    >
                        <div slot-scope="{ node }" class="node-container d-flex align-items-center">
                            <span class="treenode-icon">
                                <i :class="node.data.icon" aria-hidden="true"></i>
                            </span>
                            {{ node.text }}
                        </div>
                    </liquor-tree>
                </div>
                <div v-else class="tree-area-placeholder">
                    {{ "메뉴 데이터가 없습니다." }}
                </div>
            </div>
            <template #footer="{ hide }">
                <div style="display: flex; flex: 1; padding: 1rem;">
                    <button
                        type="button"
                        class="btn btn-primary"
                        style="margin-right: 0.5rem; flex: 1;"
                        @click="selectParentMenu"
                    >
                        {{ "선택" }}
                    </button>
                    <button type="button" class="btn btn-secondary" style="margin-left: 0.5rem; flex: 1;" @click="hide">
                        {{ "취소" }}
                    </button>
                </div>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
import backEndApi from "@src/api/backEndApi";

export default {
    props: ["detailInfo", "mode"],
    components: {},
    data() {
        return {
            groupList: [],
            groupCheck: [],
            menuList: [],
            isShow: true,
            treeData: [],
            treeOptions: {
                parentSelect: true,
                dnd: false,
            },
            selectNode: null,
        };
    },
    computed: {
        isNewMode() {
            return this.mode == "new";
        },
        selectMenuName() {
            const findTitleByMenuIdx = (data, targetMenuIdx) => {
                for (let item of data) {
                    if (item.menuIdx == targetMenuIdx) return item.title;
                    if (item.children && item.children.length > 0) {
                        const foundTitle = findTitleByMenuIdx(item.children, targetMenuIdx);
                        if (foundTitle) return foundTitle;
                    }
                }
                return null;
            };
            return findTitleByMenuIdx(this.menuList, this.detailInfo.publish.parentMenuIdx);
        },
    },
    watch: {
        treeData() {
            this.isShow = false;
            this.$nextTick(() => {
                this.isShow = true;
            });
        },
    },
    async created() {
        await this.getAccesGroupList();
        await this.searchMenuTree();
    },
    mounted() {},
    methods: {
        async getAccesGroupList() {
            try {
                let result = await backEndApi.group.searchGroup();
                if (result.status == 200) {
                    this.groupList = result.data;
                    if (this.isNewMode) {
                        this.groupList.forEach((list) => {
                            this.detailInfo.grants.push({
                                userGroupIdx: list.groupIdx,
                                accessYn: "N",
                            });
                        });
                    }
                    this.detailInfo.grants.forEach((group) => {
                        this.groupCheck.push(group.accessYn == "Y" ? true : false);
                    });
                } else this.alertWarning(`${result.data.message}`);
            } catch (e) {
                console.error(e);
            }
        },
        chageGroupAccess() {
            this.groupCheck.forEach((check, index) => {
                let group = this.detailInfo.grants[index];
                group.accessYn = check ? "Y" : "N";
                this.detailInfo.grants[index] = group;
            });
        },
        async searchMenuTree() {
            try {
                let result = await backEndApi.menu.searchMenuTree();
                if (result.status == 200) {
                    this.menuList = result.data;
                    let node = await this.getCustomTreeData(this.menuList);
                    this.treeData = node;
                } else {
                    this.alertWarning(`${result.data.message}`);
                }
            } catch (e) {
                console.error(e);
            }
        },
        getCustomTreeData(data) {
            return data.map((node) => {
                const { title, children, ...rest } = node;
                return {
                    text: title,
                    state: { expanded: true },
                    data: rest,
                    children: !this.isEmpty(children) ? this.getCustomTreeData(children) : [],
                };
            });
        },
        onNodeSelected(node) {
            this.selectNode = node.data;
        },
        async selectParentMenu() {
            console.log(this.selectNode);
            this.detailInfo.publish.parentMenuIdx = this.selectNode.menuIdx;
            await this.closeSidebar();
        },
        closeSidebar() {
            this.$refs.menuSideBar.hide();
        },
    },
};
</script>

<style scoped>
.tree-area {
    /* height: Calc(100% - 70px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 8px;
    margin-bottom: 0px !important;

    border-radius: 0px 0px 10px 10px;
    border-left: solid #eee 1px;
    border-top: solid #eee 1px;
    border-right: solid #ddd 1px;
    border-bottom: solid #ddd 1px;
    font-size: 0.8rem; */
    border: 1px solid #ececec;
    border-radius: 10px;
    padding: 0.5rem;
}
.treenode-icon {
    /* color: #727272; */
    color: #5775ad;
    background-color: #f5f5f5;
    border: solid #e2e2e2 1px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    transition: background-color 0.5s;
    width: 26px;
    height: 26px;
    font-size: 16px;
}
.access-ctn {
    width: 100%;
    height: 120px;
    border: 1px solid #ececec;
    border-radius: 5px;
    padding: 1rem;
}

.icon-ctn {
    width: 100%;
    height: 100%;
    background-color: #eceff1;
    font-size: 2rem;
    color: #455a64;
    border-radius: 5px;
    border-left: solid 1px #cfd8dc;
    border-top: solid 1px #cfd8dc;
    border-right: solid 1px #90a4ae;
    border-bottom: solid 1px #90a4ae;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
