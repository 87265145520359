<template>
    <div style="width: 100%; height: 100%;">
        <div ref="compareLineChart" style="width: 100%; height: 100%"></div>
    </div>
</template>

<script>
import * as echarts from "echarts";

export default {
    props: ["chartDisp", "chartResize"],
    components: {},
    data() {
        return {
            chart: null,
        };
    },
    computed: {},
    watch: {
        chartDisp() {
            console.log("watch chartDisp");
            if (this.chart) {
                this.chart.resize(); // chartDisp 변경 시 차트를 리사이즈
            }
        },
        chartResize() {
            if (this.chart) {
                this.chart.resize(); // chartResize 변경 시 차트를 리사이즈
            }
        },
    },
    created() {},
    mounted() {
        this.renderChart();
    },
    methods: {
        renderChart() {
            this.chart = echarts.init(this.$refs.compareLineChart);
            const option = {
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
                },
                yAxis: {
                    type: "value",
                },
                series: [
                    {
                        data: [820, 932, 901, 934, 1290, 1330, 1320],
                        type: "line",
                        areaStyle: {},
                    },
                ],
            };
            this.chart.setOption(option);
            window.addEventListener("resize", () => {
                this.chart.resize();
            });
        },
    },
};
</script>
