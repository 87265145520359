<template>
    <div style="width: 100%;">
        <div style="width: 100%; display: flex; justify-content: center;">
            <h5>{{ "분석 정보" }}</h5>
        </div>
        <table class="table table-bordered mb-0">
            <tbody class="text-center">
                <tr>
                    <th class="table-secondary" scope="row">{{ "분석명" }}</th>
                    <td style="background-color: #eeeeee;">{{ "에너지 수급량 분석" }}</td>
                </tr>
                <tr>
                    <th class="table-secondary" scope="row">{{ "분석 일시" }}</th>
                    <td style="background-color: #eeeeee;">{{ "2024-10-31 21:16" }}</td>
                </tr>
                <tr>
                    <th class="table-secondary" scope="row">{{ "분석 방법" }}</th>
                    <td style="background-color: #eeeeee;">{{ "통계 분석" }}</td>
                </tr>
                <tr>
                    <th class="table-secondary" scope="row">{{ "분석 기간" }}</th>
                    <td style="background-color: #eeeeee;">{{ "2024-09 ~ 2024-10" }}</td>
                </tr>
                <tr>
                    <th class="table-secondary" scope="row">{{ "비교 방법" }}</th>
                    <td style="background-color: #eeeeee;">{{ "기간 비교" }}</td>
                </tr>
                <tr>
                    <th class="table-secondary" scope="row">{{ "비교 기간" }}</th>
                    <td style="background-color: #eeeeee;">{{ "2023-09 ~ 2023-10" }}</td>
                </tr>
            </tbody>
        </table>
        <table class="table table-bordered mb-0">
            <tbody>
                <tr>
                    <td class="table-secondary" style="font-weight: bold;">{{ "분석 대상" }}</td>
                </tr>
                <tr>
                    <td style="background-color: #eeeeee;">{{ "30 전체 에너지 수급량 (BLDOVA.CSTN,kWh)" }}</td>
                </tr>
            </tbody>
        </table>
        <table class="table table-bordered mb-0">
            <tbody>
                <tr>
                    <td class="table-secondary" style="font-weight: bold;">{{ "구성 데이터" }}</td>
                </tr>
                <tr>
                    <td style="background-color: #eeeeee;">
                        <p>{{ "50 8F전력수급량 (ENCSTN.ELEC-8F,kWh)" }}</p>
                        <p>{{ "60 7F전력수급량 (ENCSTN.ELEC-7F,kWh)" }}</p>
                        <p>{{ "70 세종전력수급량 (ENCSTN.ELEC-SJ,kWh)" }}</p>
                    </td>
                </tr>
            </tbody>
        </table>
        <table class="table table-bordered">
            <tbody>
                <tr>
                    <td class="table-secondary" style="font-weight: bold;">{{ "분석 설정에 대한 설명" }}</td>
                </tr>
                <tr>
                    <td style="background-color: #eeeeee;">{{ "분석 설정에 대한 설명" }}</td>
                </tr>
            </tbody>
        </table>
        <div style="width: 100%; margin-bottom: 10px;">
            <div style="width: 100%; display: flex; justify-content: center; margin-bottom: 10px;">
                <h5 style="margin: 0px 10px 0px 0px !important;">{{ "주요 이벤트" }}</h5>
                <div class="search-btn">
                    <span>{{ "…" }}</span>
                </div>
            </div>
            <div class="list-ctn">
                <p>{{ "2024-11-01 이상치 발생" }}</p>
                <p>{{ "2024-10-01 ~ 2024-10-02 전체 정전" }}</p>
                <p>{{ "2024-10-01 ~ 2024-10-02 전체 정전" }}</p>
                <p>{{ "2024-10-01 ~ 2024-10-02 전체 정전" }}</p>
                <p>{{ "2024-10-01 ~ 2024-10-02 전체 정전" }}</p>
                <p>{{ "2024-10-01 ~ 2024-10-02 전체 정전" }}</p>
            </div>
        </div>
        <div style="width: 100%;">
            <div style="width: 100%; display: flex; justify-content: center; margin-bottom: 10px;">
                <h5 style="margin: 0px 10px 0px 0px !important;">{{ "분석 기록( 최대 5건)" }}</h5>
                <div class="search-btn">
                    <span>{{ "…" }}</span>
                </div>
            </div>
            <div class="list-ctn">
                <p>{{ "2024-11-01 정상적으로 수집과 집계가 이루어졌고 특이사항 없음" }}</p>
                <p>{{ "2024-11-01 정상적으로 수집과 집계가 이루어졌고 특이사항 없음" }}</p>
                <p>{{ "2024-11-01 정상적으로 수집과 집계가 이루어졌고 특이사항 없음" }}</p>
                <p>{{ "2024-11-01 정상적으로 수집과 집계가 이루어졌고 특이사항 없음" }}</p>
                <p>{{ "2024-11-01 정상적으로 수집과 집계가 이루어졌고 특이사항 없음" }}</p>
                <p>{{ "2024-11-01 정상적으로 수집과 집계가 이루어졌고 특이사항 없음" }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [],
    components: {},
    data() {
        return {};
    },
    computed: {},
    watct: {},
    created() {},
    mounted() {},
    methods: {},
};
</script>

<style scoped>
.analysis-info {
    width: 100%;
}

.analysis-info > tbody > tr > th {
    padding: 8px;
    background-color: #e0e0e0;
}

.analysis-info > tbody > tr > td {
    padding: 8px;
    background-color: #eeeeee;
}

.search-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 20px;
    border: 1px solid #ececec;
    background-color: #d6d6d6;
    border-radius: 5px;
    cursor: pointer;
}

.list-ctn {
    width: 100%;
    height: 120px;
    overflow-y: auto;
    border: 1px solid #ececec;
    border-radius: 5px;
    padding: 10px;
}
</style>
