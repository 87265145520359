<template>
    <div class="heatMapContainer">
        <div class="HourHeatMap">
            <trend-hour-heat-map-panel />
        </div>
        <div class="DailyHeatMap">
            <trend-daily-heat-map-vue />
        </div>
    </div>
</template>

<script>
import TrendDailyHeatMapVue from "./TrendDailyHeatMap.vue";
import TrendHourHeatMapPanel from "./TrendHourHeatMapPanel.vue";

export default {
    components: {
        TrendHourHeatMapPanel,
        TrendDailyHeatMapVue,
    },
};
</script>

<style scoped>
.heatMapContainer {
    width: 100%;
    height: 30%;
    display: flex;
    /* background-color: brown; */
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}
.HourHeatMap {
    width: 75%;
    height: 100%;
}
.DailyHeatMap {
    width: 25%;
    height: 100%;
    /* background-color: green; */
}
</style>
