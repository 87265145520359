<template>
    <div class="row m-0">
        <!--  :toggleFields="['enabled']" :transCodes="transCodes" -->
        <!--  @row-click="handleItemClick" @toggle-changed="handleItemToggle" -->
        <TableList
            ref="TableList"
            :title="'분석 기록 목록'"
            :keyField="'modelIdx'"
            :columns="columns"
            :rows="commentList"
            :controls="controls"
            :mode="'edit'"
            :useMaxHeight="true"
            :useFilter="false"
            @button-click="handleListButtonClick"
        />
        <b-modal ref="addAnalCommentModal" centered size="lg">
            <template #modal-header>
                <h5 class="mb-0">{{ "분석 기록 추가" }}</h5>
            </template>
            <div class="row m-0">
                <label for="comment">{{ "분석 기록 입력" }}</label>
                <input v-model="comment" type="text" class="form-control" />
            </div>
            <template #modal-footer>
                <div style="display: flex; flex: 1;">
                    <button
                        type="button"
                        class="btn btn-primary"
                        style="flex: 1; margin-right: 0.5rem;"
                        @click="saveAnalysisComment"
                    >
                        {{ "추가" }}
                    </button>
                    <button
                        type="button"
                        class="btn btn-secondary"
                        style="flex: 1; margin-left: 0.5rem;"
                        @click="() => $refs.addAnalCommentModal.hide()"
                    >
                        {{ "닫기" }}
                    </button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import backEndApi from "@src/api/backEndApi";
import TableList from "@views/component/v2.1/ListDetailView/TableList.vue";
import moment from "moment";

export default {
    props: ["detailInfo"],
    components: {
        TableList,
    },
    data() {
        return {
            commentList: [],
            comment: null,
            controls: [
                { event: "regist", label: "+", class: "btn-primary" },
                { event: "delete", label: "-", class: "btn-danger" },
            ],
            columns: [
                {
                    label: this.$t("기록 일시"),
                    field: "regDate",
                    type: "text",
                    width: "150px",
                },
                {
                    label: this.$t("기록 내용"),
                    field: "comment",
                    type: "text",
                },
                {
                    label: this.$t("변경 일시"),
                    field: "modDate",
                    type: "text",
                    width: "150px",
                },
                {
                    label: this.$t("작성자"),
                    field: "regWorker",
                    type: "text",
                    width: "150px",
                },
            ],
        };
    },
    computed: {},
    watch: {},
    created() {
        this.getAnalysisCommentList();
    },
    mounted() {},
    methods: {
        async getAnalysisCommentList() {
            if (this.detailInfo.modelIdx) {
                try {
                    let result = await backEndApi.analysisModel.getAnalysisCommentList(this.detailInfo.modelIdx);
                    if (result.status == 200) {
                        this.commentList = result.data;
                        this.commentList.forEach((comment) => {
                            if (!this.isEmpty(comment.regDate))
                                comment.regDate = moment(comment.regDate).format("YYYY-MM-DD HH:mm:ss");
                            if (!this.isEmpty(comment.modDate))
                                comment.modDate = moment(comment.modDate).format("YYYY-MM-DD HH:mm:ss");
                        });
                    } else {
                        return;
                        // this.alertWarning(`${result.data.message}`);
                    }
                } catch (e) {
                    console.error(e);
                }
            } else return;
        },
        async handleListButtonClick(event, options) {
            switch (event) {
                case "regist":
                    await this.registNew();
                    break;

                case "delete":
                    await this.deleteChecked();
                    break;

                default:
                    console.warn("Unhandled Component Event. event:", event, "options:", options);
                    break;
            }
        },
        registNew() {
            this.$refs.addAnalCommentModal.show();
        },
        async deleteChecked() {
            try {
                let confirm = await this.alertConfirmWarning("선택한 목록을 삭제하시겠습니까?");
                if (!confirm.value) return;

                const checked = this.$refs["TableList"].getCheckedRows();
                if (!this.isEmpty(checked)) {
                    checked.forEach(async (item) => {
                        let result = await backEndApi.analysisModel.deleteAnalysisComment(item.modelIdx, item.regDate);
                        if (result.status !== 200) {
                            this.alertWarning(`${result.data.message}`);
                            return;
                        }
                    });
                    await this.alertNoti("선택한 목록을 삭제하였습니다.");
                    await this.getAnalysisCommentList();
                } else {
                    this.alertNoti("목록을 선택해주세요.");
                }
            } catch (e) {
                this.alertWarning("삭제에 실패하였습니다.");
                console.error(e);
            }
        },
        async saveAnalysisComment() {
            let comment = {
                modelIdx: this.detailInfo.modelIdx,
                comment: this.comment,
            };
            try {
                let result = await backEndApi.analysisModel.saveAnalysisComment(comment);
                if (result.status == 200) {
                    await this.alertNoti("분석 기록을 저장하였습니다.");
                    await this.getAnalysisCommentList();
                    await this.$refs.addAnalCommentModal.hide();
                } else {
                    this.alertWarning(`${result.data.message}`);
                }
            } catch (e) {
                console.error(e);
            }
        },
    },
};
</script>

<style scoped></style>
