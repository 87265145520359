<template>
    <div style="width: 100%; height: 100%;">
        <div ref="compareDoughnutChart" style="width: 100%; height: 100%"></div>
    </div>
</template>

<script>
import * as echarts from "echarts";

export default {
    props: ["chartResize"],
    components: {},
    data() {
        return {
            chart: null,
        };
    },
    computed: {},
    watch: {
        chartResize() {
            if (this.chart) {
                this.chart.resize(); // chartResize 변경 시 차트를 리사이즈
            }
        },
    },
    created() {},
    mounted() {
        this.renderChart();
    },
    methods: {
        renderChart() {
            this.chart = echarts.init(this.$refs.compareDoughnutChart);
            const option = {
                tooltip: {
                    trigger: "item",
                },
                series: [
                    {
                        name: "Access From",
                        type: "pie",
                        radius: ["40%", "70%"],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: "center",
                        },
                        emphasis: {
                            label: {
                                show: false,
                                fontSize: 40,
                                fontWeight: "bold",
                            },
                        },
                        labelLine: {
                            show: false,
                        },
                        data: [
                            { value: 1048, name: "Search Engine" },
                            { value: 735, name: "Direct" },
                            { value: 580, name: "Email" },
                            { value: 484, name: "Union Ads" },
                            { value: 300, name: "Video Ads" },
                        ],
                    },
                ],
            };
            this.chart.setOption(option);
            window.addEventListener("resize", () => {
                this.chart.resize();
            });
        },
    },
};
</script>
