<template>
    <div class="detail-container">
        <div style="height: 100%">
            <div class="detail-header">
                <div class="col detail-title">
                    <!-- <span class="title">{{ vie/wTitle }}</span> -->
                    <span class="badge title1">{{ detailInfo.modelName ?? "-" }}</span>
                    <span class="badge title2"> {{ detailInfo.category ?? "-" }} </span>
                    <span class="badge others"> {{ targetPtName ?? "-" }} </span>
                    <span class="badge others"> {{ `연관 데이터 수 (${detailInfo.ptMaps.length ?? "-"}개)` }} </span>
                    <span class="badge others"> {{ !isEmpty(detailInfo.publish.path) ? "ON" : "OFF" }} </span>
                </div>
                <div class="detail-control">
                    <div class="btn-group mr-2">
                        <button type="button" class="btn btn-sm btn-secondary" @click="changeExpandStatus">
                            {{ "목록" }}
                        </button>
                        <button type="button" class="btn btn-sm btn-warning" @click="saveData">{{ "저장" }}</button>
                        <button type="button" class="btn btn-sm btn-primary" @click="newAnalysisModel">
                            {{ "신규" }}
                        </button>
                        <button type="button" class="btn btn-sm btn-danger" @click="deleteAnalysisModel">
                            {{ "삭제" }}
                        </button>
                    </div>
                </div>
            </div>
            <div style="width: 100%; height: calc(100% - 50px)">
                <div style="width: 100%">
                    <div block v-b-toggle.setting class="accordion-title">
                        <span>{{ "분석 조건 설정" }}</span>
                        <span class="when-opened">
                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                        </span>
                        <span class="when-closed">
                            <i class="fa fa-chevron-up" aria-hidden="true"></i>
                        </span>
                    </div>
                    <b-collapse
                        v-model="settingCollapse"
                        visible
                        ref="setting"
                        id="setting"
                        class="collapse-body setting-itemview"
                        accordion="setting"
                    >
                        <div ref="exportPDF">
                            <SettingPanel
                                ref="SettingPanel"
                                :detailInfo="detailInfo"
                                :analysisList="analysisList"
                                @exportToPDF="exportToPDF"
                                @changeAnalysisWay="changeAnalysisWayFunc"
                                @result="getAnalysisResultData"
                            />
                        </div>
                    </b-collapse>
                </div>
                <div
                    style="width: 100%;"
                    :style="{ height: settingCollapse ? 'calc(100% - 240px)' : 'calc(100% - 50px)' }"
                >
                    <div block v-b-toggle.analysisResult class="accordion-title">
                        <span>{{ "분석 결과" }}</span>
                        <span class="when-opened">
                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                        </span>
                        <span class="when-closed">
                            <i class="fa fa-chevron-up" aria-hidden="true"></i>
                        </span>
                    </div>
                    <b-collapse
                        visible
                        ref="analysisResult"
                        id="analysisResult"
                        class="collapse-body result-itemview"
                        accordion="analysisResult"
                    >
                        <div v-if="!isEmpty(resultData)" ref="result" class="row">
                            <!-- 분석 결과 (차트) -->
                            <div class="pl-0" :class="!showReltnGoal ? 'col-9' : 'col-12'">
                                <ResultChartPanel
                                    :detailInfo="detailInfo"
                                    :chartResize="chartResize"
                                    :analysisMethod="analysisMethod"
                                />
                            </div>
                            <!-- 분석 결과 (표) -->
                            <div v-if="!showReltnGoal" class="col-3 pr-0">
                                <ResultInfoPanel />
                            </div>
                        </div>
                        <div v-else class="list-placeholder">
                            <span class="list-notification-icon">
                                <i class="fas fa-external-link-alt"></i>
                            </span>
                            <span class="list-notification-text">
                                {{ "분석 조건을 설정해서 분석을 실행해주세요." }}
                            </span>
                            <!-- {{ detailInfo }} -->
                        </div>
                    </b-collapse>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import backEndApi from "@src/api/backEndApi";
import SettingPanel from "./panels/SettingPanel.vue";
import ResultChartPanel from "./panels/ResultChartPanel.vue";
import ResultInfoPanel from "./panels/ResultInfoPanel.vue";
import html2pdf from "html2pdf.js";

export default {
    props: ["analysisList"],
    components: {
        SettingPanel,
        ResultChartPanel,
        ResultInfoPanel,
    },
    data() {
        return {
            detailInfo: null,
            analysisWay: "",
            chartResize: false,
            settingCollapse: true,
            showReltnGoal: false,

            resultData: null,
            analysisMethod: null,
        };
    },
    computed: {
        targetPtName() {
            let find = this.$store.state.pointList.find((point) => point.ptIdx == this.detailInfo.ptIdx);
            return !this.isEmpty(find) ? find.ptName : null;
        },
    },
    watch: {},
    created() {
        this.initData();
    },
    mounted() {},
    methods: {
        setData(modelIdx) {
            if (this.isEmpty(modelIdx)) {
                this.initData();
            } else {
                this.loadData(modelIdx);
            }
        },
        async initData() {
            this.detailInfo = {
                modelIdx: null,
                modelName: null,
                category: null,
                icon: null,
                analysisMethod: null,
                dispType: null,
                dispUnit: null,
                ptIdx: null,
                timeDsvn: null,
                fromDate: null,
                toDate: null,
                compareType: null,
                fromDateComp: null,
                toDateComp: null,
                enabled: "Y",
                description: null,
                ptMaps: [],
                publish: {
                    parentMenuIdx: null,
                    title: null,
                    path: null,
                    params: null,
                },
                grants: [],
                report: {
                    paperSize: null,
                    paperDirection: null,
                },
                comments: [],
            };
            this.resultData = [];
            this.$nextTick(() => {
                this.$refs["SettingPanel"].setNewMode();
            });
        },
        async loadData(modelIdx) {
            const edit = this.$refs["SettingPanel"].isEditMode;
            if (edit) {
                const confirm = await this.notifyNewAnalModel();
                if (confirm) {
                    await this.getAnalysisModel(modelIdx);
                    this.resultData = [];
                } else return;
            } else await this.getAnalysisModel(modelIdx);
        },
        async getAnalysisModel(modelIdx) {
            try {
                let result = await backEndApi.analysisModel.getAnalysisModel(modelIdx);
                if (result.status == 200) {
                    this.detailInfo = result.data;
                    await this.$refs["SettingPanel"].setViewMode();
                    await this.$refs["SettingPanel"].setDayTime(this.detailInfo);
                } else {
                    this.alertWarning(`${result.data.message}`);
                }
            } catch (e) {
                console.error(e);
            }
        },
        async notifyNewAnalModel() {
            const result = await this.alertConfirmWarning("편집중인 내용을 무시하고 종료할까요?");
            if (result.value) return true;
            else return false;
        },
        async newAnalysisModel() {
            const edit = this.$refs["SettingPanel"].isEditMode;
            if (edit) {
                const confirm = await this.notifyNewAnalModel();
                if (confirm) {
                    await this.initData();
                } else return;
            } else await this.initData();
        },
        clearData() {
            this.detailInfo = null;
            this.$forceUpdate();
        },
        changeAnalysisWayFunc(val) {
            console.log("analysisWay change", val);
            this.analysisWay = val;
        },
        changeExpandStatus() {
            this.chartResize = !this.chartResize;
            this.$emit("expand");
        },
        exportToPDF() {
            console.log("export pdf");
            //window.scrollTo(0, 0);
            html2pdf(this.$refs.result, {
                margin: 5,
                filename: "document.pdf",
                image: { type: "png", quality: 1 },
                html2canvas: {
                    scrollY: 0,
                    scale: 1,
                    dpi: 300,
                    letterRendering: true,
                    //pdf에 출력하지 않아야할 dom이 있다면 해당 옵션 사용
                    // ignoreElements: function (element) {
                    //     if (element.id == "pdf-button-area") {
                    //         return true;
                    //     }
                    // }
                },
                jsPDF: {
                    orientation: "landscape",
                    unit: "mm",
                    format: "a4",
                    compressPDF: true,
                },
            });
        },
        async saveData() {
            try {
                let result = await backEndApi.analysisModel.saveAnalysisModel(this.detailInfo);
                if (result.status == 200) {
                    await this.alertNoti("저장하였습니다.");
                    await this.$emit("refresh");
                    await this.getAnalysisModel(result.data.data.modelIdx);
                } else {
                    this.alertWarning(`${result.data.message}`);
                }
            } catch (e) {
                console.error(e);
            }
        },
        async deleteAnalysisModel() {
            if (this.$refs["SettingPanel"].isNewMode) {
                this.alertNoti("신규 입력시에는 삭제할 수 없습니다.");
                return;
            } else {
                let confirm = await this.alertConfirmWarning("해당 분석 모델을 삭제하시겠습니까?");
                if (!confirm.value) return;
                else {
                    try {
                        let result = await backEndApi.analysisModel.deleteAnalysisModel(this.detailInfo.modelIdx);
                        if (result.status == 200) {
                            await this.alertNoti("삭제하였습니다.");
                            await this.initData();
                            await this.$emit("refresh");
                        } else this.alertWarning(`${result.data.message}`);
                    } catch (e) {
                        console.erroe(e);
                    }
                }
            }
        },
        async getAnalysisResultData() {
            let analysisMethod = this.detailInfo.analysisMethod;

            const searchInfo = {
                ptIdx: this.detailInfo.ptIdx,
                timeDsvn: this.detailInfo.timeDsvn,
                fromDate: this.detailInfo.fromDate,
                toDate: this.detailInfo.toDate ? this.detailInfo.toDate : this.detailInfo.fromDate,
                ptMaps: [],
                compareType: this.detailInfo.compareType,
                fromDateComp: "",
                toDateComp: "",
                datasetMode: "Divided",
            };

            this.detailInfo.ptMaps.forEach((pt) => searchInfo.ptMaps.push(pt.ptIdx));

            // fromDateComp 설정
            if (this.detailInfo.compareType === "User") {
                searchInfo.fromDateComp = this.detailInfo.fromDateComp;
            } else {
                searchInfo.fromDateComp = null;
            }

            // toDateComp 설정
            if (this.detailInfo.compareType === "User") {
                if (this.detailInfo.toDateComp) {
                    searchInfo.toDateComp = this.detailInfo.toDateComp;
                } else {
                    searchInfo.toDateComp = this.detailInfo.fromDateComp;
                }
            } else {
                searchInfo.toDateComp = null;
            }

            if (analysisMethod == "Trend") {
                this.showReltnGoal = false;
                this.analysisMethod = analysisMethod;
                await this.getAnalyzeTrend(searchInfo); // 트렌드 분석 API 호출 함수
            } else if (analysisMethod == "Diff") {
                this.showReltnGoal = false;
                this.analysisMethod = analysisMethod;
                this.resultData = [1, 2, 3]; // 임시 테스트 용도
            } else if (analysisMethod == "Comp") {
                this.showReltnGoal = false;
                this.analysisMethod = analysisMethod;
                this.resultData = [1, 2, 3]; // 임시 테스트 용도
            } else if (analysisMethod == "Reltn") {
                this.showReltnGoal = true;
                this.analysisMethod = analysisMethod;
                this.resultData = [1, 2, 3]; // 임시 테스트 용도
            } else if (analysisMethod == "Goal") {
                this.showReltnGoal = true;
                this.analysisMethod = analysisMethod;
                this.resultData = [1, 2, 3]; // 임시 테스트 용도
            } else if (analysisMethod == "Cost") {
                this.showReltnGoal = false;
                this.analysisMethod = analysisMethod;
                this.resultData = [1, 2, 3]; // 임시 테스트 용도
            } else {
                this.resultData = []; // 임시 테스트 용도
                this.alertWarning("분석 결과를 조회할 수 없습니다.");
            }
        },
        async getAnalyzeTrend(searchInfo) {
            try {
                let result = await backEndApi.analysisModel.getAnalyzeTrend(searchInfo);
                if (result.status == 200) {
                    this.resultData = result.data;
                } else {
                    this.alertWarning(`${result.data.message}`);
                }
            } catch (e) {
                console.error(e);
            }
        },
    },
};
</script>

<style scoped>
.row {
    width: 100%;
    margin: 0px !important;
}

.detail-container {
    height: 100%;
    width: 100%;
    padding: 1rem 1rem 1rem 1rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    font-size: 12px;
}

.detail-header {
    margin-bottom: 0px !important;
    padding: 8px 16px 8px 16px;
    overflow: hidden;

    display: flex;
    justify-content: space-between;
    align-items: center;

    border-radius: 6px 6px 0px 0px;
    border: solid #ececec 1px;
    border-bottom: 1px solid #ccc;
    background-color: #f1f1f1;
    font-weight: bold;
}

.detail-header .detail-title {
    padding: 0 !important;

    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.detail-control {
    display: flex;
    justify-content: flex-end;
}

.title {
    /* font-size: 14px; */
    font-weight: 600;
    margin-right: 20px;
}

.badge {
    font-size: 12px;
    font-weight: 500;
    margin-right: 4px;
    display: inline-block;
    min-width: 20px;
    padding: 0px 10px 0px 10px;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    /* background-color: #777; */
    line-height: 22px;
    border-radius: 8px;
}

.badge.title1 {
    background-color: #ff6d00;
    color: #fff;
}

.badge.title2 {
    background-color: #546e7a;
    color: #fff;
}

.badge.others {
    background-color: #cfd8dc;
    color: #000;
}

.accordion-title {
    background-color: #ececec;
    padding: 10px;
    margin-bottom: 0px !important;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    border: solid #ececec 1px;
    border-radius: 6px 6px 0px 0px;
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
    display: none;
}

.setting-itemview {
    /* height: 83%; */
    width: 100%;
    padding: 0.7rem;
    justify-content: center;
    align-items: center;
    /* overflow-y: scroll; */
    border: solid #ececec 1px;
    border-radius: 0px 0px 6px 6px;
    /* padding: 1rem 2rem; */
}

.result-itemview {
    /* height: 83%; */
    height: calc(100% - 50px);
    overflow-y: auto;
    width: 100%;
    padding: 0.7rem;
    justify-content: center;
    align-items: center;
    /* overflow-y: scroll; */
    border: solid #ececec 1px;
    border-radius: 0px 0px 6px 6px;
    /* padding: 1rem 2rem; */
}

.list-placeholder {
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.list-notification-icon {
    padding: 1rem;
    color: #444;
    font-size: 48px;
}

.list-notification-text {
    font-size: 15px;
    font-weight: bold;
}
</style>
