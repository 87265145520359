<template>
  <div class="lineChartContainer" ref="chart" />
</template>

<script>
import * as echarts from "echarts";

export default {
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.drawChart();
    window.addEventListener("resize", () => this.resizeChart); // 창 크기 변경 시 차트 크기 조정
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => this.resizeChart);
    // if (this.chart) {
    //   this.chart.dispose();
    // }
  },
  methods: {
    drawChart() {
      this.chart = echarts.init(this.$refs.chart);
      const option = {
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"], // X축 데이터
        },
        yAxis: {
          type: "value",
        },
        grid: {
          width: "80%",
          height: "90%",
        },
        series: [
          {
            name: "Sales",
            type: "line",
            data: [120, 132, 101, 134, 90, 230, 210], // 첫 번째 데이터
            areaStyle: {
              color: "rgba(144, 238, 144, 0.4)", // 첫 번째 영역의 색상
            },
            itemStyle: {
              color: "rgba(144, 238, 144, 1)", // 첫 번째 라인 색상
            },
            lineStyle: {
              color: "rgba(0, 128, 0, 0.8)",
              width: 2,
            },
          },
          {
            name: "Expenses",
            type: "line",
            data: [90, 150, 80, 180, 70, 200, 160], // 두 번째 데이터
            areaStyle: {
              color: "rgba(255, 99, 71, 0.4)", // 두 번째 영역의 색상
            },
            itemStyle: {
              color: "rgba(255, 99, 71, 1)", // 두 번째 라인 색상
            },
            lineStyle: {
              color: "rgba(255, 69, 0, 0.8)",
              width: 2,
            },
          },
        ],
      };
      this.chart.setOption(option);
    },
    resizeChart() {
      if (this.chart) {
        this.chart.resize();
      }
    },
  },
};
</script>

<style scoped>
.lineChartContainer {
  margin: 10px 0;
  width: 100%;
  height: 30%; /* 차트의 높이 설정 */
  /* background-color: burlywood; */
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}
</style>
