<template>
    <div class="ld-container" style="height: 90vh; width: 100%">
        <div class="row" style="height:100%">
            <div v-if="!expand" class="list-area" :class="expand ? '' : 'col-3'">
                <div class="row">
                    <HeaderBox :title="`${$t('데이터 분석')} > ${$t('에너지 데이터 분석')}`" />
                </div>
                <div style="height: calc(100% - 40px)">
                    <div v-if="!isEmpty(analysisList)">
                        <!-- @button-click="handleListButtonClick" @row-click="handleItemClick"  -->
                        <TableList
                            ref="TableList"
                            :title="'에너지 데이터 분석 목록'"
                            :columns="columns"
                            :rows="analysisList"
                            :keyField="'modelIdx'"
                            :groupField="'category'"
                            :toggleFields="['enabled']"
                            :transCodes="transCodes"
                            :controls="controls"
                            :mode="'edit'"
                            :useMaxHeight="true"
                            :fixedHeader="false"
                            @button-click="handleListButtonClick"
                            @row-click="handleItemClick"
                            @toggle-changed="handleItemToggle"
                        />
                    </div>
                    <div v-else class="list-placeholder">
                        <span class="list-notification-icon">
                            <i class="fas fa-external-link-alt"></i>
                        </span>

                        <span class="list-notification-text">
                            {{ "등록된 분석 정보가 없습니다." }}
                        </span>
                        <span class="list-notification-text">
                            {{ "분석 정보를 추가하세요." }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="detail-area" :class="expand ? 'col-12' : 'col-9'">
                <!-- <EquipInfoDetail ref="EquipInfoDetail" :title="'설비 인벤토리'" :equipIdx="equipIdx" :equipType="equipType"
                    @data-updated="handleDetailUpdated" @closed="handleDetailClosed" /> -->
                <AnalysisEditor
                    ref="AnalysisEditor"
                    :analysisList="analysisList"
                    @expand="setExpand"
                    @refresh="getAnalysisModelList"
                />
            </div>
        </div>
    </div>
</template>

<script>
import backEndApi from "@src/api/backEndApi";
import HeaderBox from "@views/component/headerBox/analysis/Header.vue";
import TableList from "@views/component/v2.1/ListDetailView/TableList.vue";
import AnalysisEditor from "./AnalysisEditor.vue";

export default {
    props: [],
    components: {
        HeaderBox,
        TableList,
        AnalysisEditor,
    },
    data() {
        return {
            analysisList: [],
            expand: true,
            controls: [
                { event: "delete", label: "삭제", class: "btn-danger" },
                { event: "status", label: "사용", class: "bg-white text-dark", options: { enabled: "Y" } },
                { event: "status", label: "미사용", class: "bg-white text-dark", options: { enabled: "N" } },
                { event: "regist", label: "추가", class: "btn-primary" },
            ],
            transCodes: [
                {
                    field: "enabled",
                    codes: this.$store.state.commonCodes.enabled,
                },
            ],
            columns: [
                {
                    label: this.$t("데이터 분석명"),
                    field: "rowTitle",
                    type: "text",
                    compositionFields: ["modelName", "modelIdx"],
                },
                {
                    label: this.$t("카테고리"),
                    field: "category",
                    type: "text",
                },
                {
                    label: this.$t("분석 대상"),
                    field: "targetPtInfo",
                    type: "text",
                    compositionFields: ["ptName", "ptIdx"],
                },
                {
                    label: this.$t("사용 여부"),
                    field: "enabled",
                    type: "text",
                },
            ],
        };
    },
    computed: {},
    watch: {},
    async created() {
        await this.getAnalysisModelList();
    },
    mounted() {},
    methods: {
        async getAnalysisModelList() {
            try {
                let result = await backEndApi.analysisModel.getAnalysisModelList();
                if (result.status == 200) {
                    this.analysisList = result.data;
                    this.analysisList.forEach((anal) => {
                        let find = this.$store.state.pointList.find((point) => point.ptIdx == anal.ptIdx);
                        if (find) anal.ptName = find.ptName;
                    });
                } else {
                    this.alertWarning(`${result.data.message}`);
                }
            } catch (e) {
                console.error(e);
            }
            return this.analysisList;
        },
        setExpand() {
            this.expand = !this.expand;
        },
        async handleListButtonClick(event, options) {
            switch (event) {
                case "regist":
                    await this.registNew();
                    break;

                case "delete":
                    await this.deleteChecked();
                    break;

                case "status":
                    await this.changeStatusChecked(options);
                    break;

                default:
                    console.warn("Unhandled Component Event. event:", event, "options:", options);
                    break;
            }
        },
        async registNew() {
            this.$refs["AnalysisEditor"].initData();
        },
        async deleteChecked() {
            try {
                let confirm = await this.alertConfirmWarning("선택한 목록을 삭제하시겠습니까?");
                if (!confirm.value) return;

                const checked = this.$refs["TableList"].getCheckedRows();
                if (!this.isEmpty(checked)) {
                    checked.forEach(async (item) => {
                        let result = await backEndApi.analysisModel.deleteAnalysisModel(item.modelIdx);
                        if (result.status !== 200) {
                            this.alertWarning(`${result.data.message}`);
                            return;
                        }
                    });
                    await this.alertNoti("선택한 목록을 삭제하였습니다.");
                    let clear = await this.getAnalysisModelList();
                    if (!this.isEmpty(clear)) await this.$refs["AnalysisEditor"].initData();
                    else {
                        this.analysisList = [];
                        await this.$refs["AnalysisEditor"].initData();
                    }
                } else {
                    this.alertNoti("목록을 선택해주세요.");
                }
            } catch (e) {
                this.alertWarning("삭제에 실패하였습니다.");
                console.error(e);
            }
        },
        async changeStatusChecked(options) {
            console.log("call changeStatusChecked", options);

            let data = options;

            try {
                const checked = this.$refs["TableList"].getCheckedRows();
                if (!this.isEmpty(checked)) {
                    checked.forEach(async (item) => {
                        if (!this.isEmpty(data)) {
                            data.modelIdx = item.modelIdx;

                            if (data.hasOwnProperty("enabled")) {
                                let result = await backEndApi.analysisModel.modelSetEnabled(data);
                                if (result.status !== 200) {
                                    this.alertWarning(`${result.data.message}`);
                                    return;
                                }
                            } else {
                                console.warn("Unknown Status Name");
                            }
                        }
                    });
                    await this.alertNoti("변경하였습니다.");
                    await this.getAnalysisModelList();
                } else {
                    this.alertNoti("상태 변경할 목록을 선택해주세요.");
                }
            } catch (e) {
                this.alertWarning("상태 변경에 실패하였습니다.");
                console.error(e);
            }
        },
        async handleItemToggle(key, field, status) {
            await this.changeStatus(key, field, status);
        },
        async changeStatus(key, field, status) {
            const data = {};
            data.modelIdx = key;
            data.field = status;

            try {
                let result = await backEndApi.analysisModel.modelSetEnabled();
                if (result.status == 200) {
                    await this.alertNoti("변경하였습니다.");
                    await this.getAnalysisModelList();
                } else {
                    this.alertWarning(`${result.data.message}`);
                }
            } catch (e) {
                console.error(e);
            }
        },
        async handleItemClick(row, idx, toggle, check) {
            if (toggle || check) return;
            this.$refs["AnalysisEditor"].setData(row.modelIdx);
        },
    },
};
</script>

<style scoped>
.ld-container {
    height: 90vh;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    padding: 10px 30px 10px 20px;
}

.ld-container .list-area {
    height: 100%;
    overflow: hidden;
    padding: 10px;
}

.ld-container .detail-area {
    height: 100%;
    margin-right: 0px;
    padding: 0px 10px 0px 10px;

    border-radius: 10px;
    border-top: 1px solid #f4f4f4;
    border-right: 2px solid #e7e7e7;
    border-bottom: 2px solid #e7e7e7;
    border-left: 1px solid #f4f4f4;
    background-color: #f9f9f9;
}

.list-placeholder {
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.list-notification-icon {
    padding: 1rem;
    color: #444;
    font-size: 48px;
}

.list-notification-text {
    font-size: 15px;
    font-weight: bold;
}
</style>
