<template>
    <div class="row m-0">
        <div class="col-12">
            <div class="row align-items-center" style="margin: 0px 0px 10px 0px !important;">
                <div class="col-2 p-0 d-flex justify-content-end">
                    <label class="m-0" for="modelName">{{ "분석명 : " }}</label>
                </div>
                <div class="col-10 pr-0">
                    <input
                        v-model="settingInfo.modelName"
                        class="form-control"
                        type="text"
                        id="modelName"
                        placeholder="분석명을 입력하세요."
                    />
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="row align-items-center" style="margin: 0px 0px 10px 0px !important;">
                <div class="col-2 p-0 d-flex justify-content-end">
                    <label class="m-0" for="category">{{ "카테고리 : " }}</label>
                </div>
                <div class="col-10 pr-0">
                    <div class="input-group">
                        <input
                            v-model="settingInfo.category"
                            class="form-control"
                            type="text"
                            id="category"
                            placeholder="카테고리를 선택하거나 직접 입력하세요."
                        />
                        <div class="input-group-append">
                            <button
                                class="btn btn-secondary"
                                type="button"
                                @click="() => $refs.selectCategoryModal.show()"
                            >
                                {{ "선택" }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="row align-items-center" style="margin: 0px 0px 10px 0px !important;">
                <div class="col-2 p-0 d-flex justify-content-end">
                    <label class="m-0" for="enabled">{{ "사용 여부 : " }}</label>
                </div>
                <div class="col-10 pr-0">
                    <select v-model="settingInfo.enabled" class="form-control" name="enabled">
                        <option :value="''">{{ "사용 여부 선택" }}</option>
                        <option v-for="item in $store.state.commonCodes.enabled" :key="item.value" :value="item.value">
                            {{ item.text }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="row" style="margin: 0px !important;">
                <div class="col-2 p-0 d-flex justify-content-end">
                    <label class="m-0" for="description">{{ "설명 : " }}</label>
                </div>
                <div class="col-10 pr-0">
                    <textarea
                        v-model="settingInfo.description"
                        class="form-control"
                        id="description"
                        :rows="4"
                        style="resize: none; overflow-y: scroll;"
                    ></textarea>
                </div>
            </div>
        </div>
        <b-modal ref="selectCategoryModal" @hidden="hideSelectCategoryModal">
            <template #modal-header>
                <h5 class="mb-0">{{ "카테고리 선택" }}</h5>
            </template>
            <div v-if="!isEmpty(categoryList)" class="row m-0 category-ctn">
                <div
                    v-for="(item, index) in categoryList"
                    :key="index"
                    class="form-check col-6"
                    style="margin-bottom: 0.2rem;"
                    @change="checkCategory"
                >
                    <input v-model="categoryCheck[index]" class="form-check-input" type="checkbox" :id="index" />
                    <label class="form-check-label" :for="index">{{ item }}</label>
                </div>
            </div>
            <div v-else class="row m-0 category-ctn">
                {{ "생성된 카테고리가 없습니다." }}
            </div>
            <template #modal-footer>
                <div style="display: flex; flex: 1;">
                    <button
                        type="button"
                        class="btn btn-primary"
                        style="flex: 1; margin-right: 0.5rem;"
                        @click="selectCategory"
                    >
                        {{ "선택" }}
                    </button>
                    <button
                        type="button"
                        class="btn btn-secondary"
                        style="flex: 1; margin-left: 0.5rem;"
                        @click="hideSelectCategoryModal"
                    >
                        {{ "취소" }}
                    </button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    props: ["detailInfo", "analysisList"],
    components: {},
    data() {
        return {
            settingInfo: {},
            categoryCheck: [],
        };
    },
    computed: {
        categoryList() {
            let uniqueArr = [];
            if (!this.isEmpty(this.analysisList)) {
                uniqueArr = this.analysisList
                    .map((item) => item.category)
                    .reduce((acc, cur) => {
                        if (acc.includes(cur)) {
                            return acc;
                        } else {
                            return [...acc, cur];
                        }
                    }, []);
            }
            return uniqueArr;
        },
    },
    watch: {},
    created() {},
    mounted() {
        this.setSettingInfo();
    },
    methods: {
        setSettingInfo() {
            this.settingInfo = {
                modelName: this.detailInfo.modelName,
                category: this.detailInfo.category,
                enabled: this.detailInfo.enabled,
                description: this.detailInfo.description,
            };
        },
        checkCategory() {},
        selectCategory() {
            this.categoryCheck.forEach((check, index) => {
                if (check) {
                    this.settingInfo.category = this.categoryList[index];
                    this.$refs.selectCategoryModal.hide();
                } else return;
            });
        },
        hideSelectCategoryModal() {
            this.$refs.selectCategoryModal.hide();
            this.categoryCheck = [];
        },
    },
};
</script>

<style scoped>
.category-ctn {
    width: 100%;
    /* height: 120px;
    overflow-y: auto; */
    border: 1px solid #ececec;
    border-radius: 5px;
    padding: 1rem;
}
</style>
