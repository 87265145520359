<template>
    <div style="width: 100%; height: 100%;">
        <div ref="waterfallChart" style="width: 100%; height: 100%"></div>
    </div>
</template>

<script>
import * as echarts from "echarts";

export default {
    props: ["chartResize"],
    components: {},
    data() {
        return {
            chart: null,
        };
    },
    computed: {},
    watch: {
        chartResize() {
            if (this.chart) {
                this.chart.resize(); // chartResize 변경 시 차트를 리사이즈
            }
        },
    },
    created() {},
    mounted() {
        this.renderChart();
    },
    methods: {
        renderChart() {
            this.chart = echarts.init(this.$refs.waterfallChart);

            const data = [500, 1000, 1500, 2000, 2500, 3000, 3500];

            const cumulativeData = data.reduce((acc, value, index) => {
                const previousSum = acc[index - 1] || 0;
                acc.push(previousSum + value);
                return acc;
            }, []);

            const startPointData = [0, ...cumulativeData.slice(0, -1)];

            const option = {
                // title: {
                //     text: "Waterfall Chart",
                // },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                    },
                },
                xAxis: {
                    type: "category",
                    data: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"],
                },
                yAxis: {
                    type: "value",
                    max: 20000,
                },
                series: [
                    {
                        name: "누적량",
                        type: "bar",
                        stack: "total",
                        itemStyle: {
                            color: "#ececec",
                        },
                        emphasis: {
                            itemStyle: {
                                color: "#ececec",
                            },
                        },
                        data: startPointData,
                    },
                    {
                        name: "사용량",
                        type: "bar",
                        stack: "total",
                        label: {
                            show: true,
                            position: "inside",
                        },
                        itemStyle: {
                            color: "#5470C6",
                        },
                        data: data,
                    },
                    {
                        name: "트렌드 라인",
                        type: "line",
                        // data: cumulativeData, // 누적 데이터를 트렌드 라인으로 사용
                        markLine: {
                            data: [
                                [
                                    { name: "Trend Line 1", xAxis: "Jan", yAxis: 0 },
                                    { xAxis: "Aug", yAxis: 20000 },
                                ],
                                [
                                    { name: "Trend Line 2", xAxis: "Jan", yAxis: 0 },
                                    { xAxis: "Aug", yAxis: 18000 }, // 두 번째 선의 끝점을 약간 높게 설정
                                ],
                            ],
                            lineStyle: {
                                type: "dashed", // 점선으로 설정
                                width: 2,
                            },
                            symbol: "circle", // 선 끝에 원형 마커 추가
                            symbolSize: 8,
                            label: {
                                show: true,
                                position: "end",
                                formatter: (param) => param.name,
                            },
                        },
                        itemStyle: {
                            color: "orange",
                        },
                    },
                ],
            };
            this.chart.setOption(option);
            window.addEventListener("resize", () => {
                this.chart.resize();
            });
        },
    },
};
</script>
